import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { useValueMyCarStore } from '~/stores/forms/valueMyCarStore'

export const useValuations = () => {
  const setValuation = () => {
    if (localStorage.getItem('tradeInVehicle') === null) return

    const valueMyCarStore = useValueMyCarStore()
    const financeCriteriaStore = useFinanceCriteriaStore()

    const tradeInVehicle = localStorage.getItem('tradeInVehicle')
    const tradeInObj =
      tradeInVehicle !== null ? JSON.parse(tradeInVehicle) : null

    const price = tradeInObj.Price
    const vehicleValue = price && parseFloat(price.substr(1).replace(/,/g, ''))

    valueMyCarStore.currentValuation = tradeInObj
    financeCriteriaStore.partExchange = vehicleValue

    localStorage.removeItem('tradeInVehicle')
  }

  return {
    setValuation
  }
}
