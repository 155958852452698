export const useMobileScrolling = () => {
  const mobileScrollSelector = 'html'
  const isMobileLayout = ref(false)
  const windowMatchMedia = ref()

  // remove listeners and snap scrolling
  onBeforeUnmount(() => {
    if (windowMatchMedia.value) {
      windowMatchMedia.value.removeEventListener('change', onMediaChange)
    }
  })

  // todo: move to default layout when breakpoints are updated
  const onMediaChange = ({ matches }: MediaQueryListEvent): any => {
    isMobileLayout.value = matches
  }

  const initMobileLayout = () => {
    if (window.matchMedia) {
      windowMatchMedia.value = window.matchMedia('(max-width: 767px)')

      windowMatchMedia.value.addEventListener('change', onMediaChange)

      isMobileLayout.value = window.innerWidth <= 767
    }
  }

  return {
    isMobileLayout,
    mobileScrollSelector,
    initMobileLayout
  }
}
