<template>
  <div class="flex flex-col">
    <section class="results h-full">
      <client-only v-if="isKioskMode">
        <Teleport to="#os-header__qr">
          <LazyOsQRCode v-if="isKioskMode" />
        </Teleport>
      </client-only>

      <section ref="resultsContent" class="results__content h-full">
        <LazyRetailerWall v-if="requiresRetailer && !requiresGeolocation" />

        <template v-else>
          <LazyResultsPromoOffer
            v-if="!isLoading && hasPromoOffer && !requiresRetailer"
            :data="promoOffer"
            class="mt-6 md:mt-14"
          />

          <LazyResultsHeader
            ref="resultsHeaderRef"
            :is-loading="isLoading || requiresRetailer"
          >
            <template
              v-if="!isKioskMode && showStockNotification"
              #searchwarning
            >
              Please try another search or click below and we’ll notify you when
              your selected car is back in stock at your preferred Showroom.
            </template>
            <template v-if="!isKioskMode && showStockNotification" #ctas>
              <OsButton
                class="mt-4"
                :is-primary="true"
                @click.prevent="notifyMe"
              >
                Notify me
              </OsButton>
            </template>
          </LazyResultsHeader>

          <client-only>
            <transition name="fade">
              <LazyOsWarning v-if="!isPersonalised" class="mb-4">
                Finance based on
                <strong>Agility (Personal Contract Plan)</strong>, with a
                deposit of <strong>17.5%</strong> of the price, a duration of
                <strong>48 months</strong>, and
                <strong>10,000 miles</strong> per year. You can personalise your
                finance options using
                <a class="cursor-pointer" @click="toggleFinance">
                  <LazyOsIcon name="Pound" class="align-sub text-blue-600" />
                  Edit finance </a
                >.
              </LazyOsWarning>
            </transition>
          </client-only>

          <LazyResultsFiltersNavigation
            ref="filterNavigationRef"
            :is-loading="isLoading"
            :is-mobile-layout="isMobileLayout"
            class="mb-6 items-center"
            @toggle-finance="toggleFinance"
          />

          <LazyFinanceResultsCalculator
            v-if="settingsStore.modes.finance"
            ref="financeCalculatorRef"
            @update-finance="onUpdateFinance"
            @toggle-finance="toggleFinance"
          />

          <div v-if="!isLoading">
            <LazyOsScrollToTop is-centered :selector="mobileScrollSelector" />
            <transition name="fade">
              <section
                v-if="vehicles.length > 0"
                ref="resultsItemsRef"
                class="results__items"
              >
                <div class="row" :class="{ 'gap-y-2': isMobileLayout }">
                  <div
                    v-for="(vehicle, index) in vehiclesCrossSelling[0]"
                    :key="vehicle.Vin"
                    class="col-12 col-lg-4 col-md-6"
                  >
                    <LazyResultsItem
                      :vehicle="vehicle"
                      :position="index + 1"
                      :is-mobile-layout="isMobileLayout"
                    />
                  </div>

                  <div
                    v-show="shouldShowCrossSell"
                    class="col-12 col-lg-4 col-md-6"
                  >
                    <LazyResultsCrossSale
                      @cross-sale-tile-found="
                        (event) => (isCrossSaleTileFound = event)
                      "
                    />
                  </div>

                  <div
                    v-for="(vehicle, index) in vehiclesCrossSelling[1]"
                    :key="vehicle.Vin"
                    class="col-12 col-lg-4 col-md-6"
                  >
                    <LazyResultsItem
                      :vehicle="vehicle"
                      :position="index + 3 + 1"
                      :is-mobile-layout="isMobileLayout"
                    />
                  </div>
                </div>
              </section>

              <LazyOsBanner
                v-else-if="!requiresRetailer"
                class="results__notification mt-4 mb-6"
              >
                <LazyOsBannerContent>
                  <OsIcon name="CarSearch" class="text-gray-100" size="6rem" />
                  <p class="text-gray-400">
                    {{ stockNotificationMessage.text }}
                  </p>
                </LazyOsBannerContent>
              </LazyOsBanner>
            </transition>
            <template v-if="bestMatchVehicles.length > 0">
              <p v-if="vehicles.length > 0" class="results__bestMatch">
                There are no more vehicles in your current search however, we
                removed some filters from your criteria and found the following
                vehicles.
              </p>

              <section
                v-for="(sortedBestMatchVehicles, i) in sortedBestMatch"
                :key="i"
                class="results__items mb-12"
              >
                <p class="font-semibold mb-6">
                  {{ sortedBestMatchVehicles?.title }}
                </p>
                <div class="row" :class="{ 'gap-2': isMobileLayout }">
                  <div
                    v-for="(vehicle, index) in sortedBestMatchVehicles?.results"
                    :key="vehicle.Vin"
                    class="col-12 col-lg-4 col-md-6"
                  >
                    <LazyResultsItem
                      :vehicle="vehicle"
                      :position="index + 1"
                      :is-mobile-layout="isMobileLayout"
                    />
                  </div>
                </div>
              </section>
            </template>

            <aside v-if="vehicles.length > 0" class="results__pagination">
              <LazyResultsPagination @search="paginationSearch" />
            </aside>

            <div ref="endResultsRef" />
          </div>

          <LazyResultsLoading v-if="isLoading" />
        </template>
      </section>
    </section>

    <LazySurveyDesktop v-if="!isMobileLayout" />
    <LazySurveyMobile v-else :used-in-form="false" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { EFormType } from '~/types/forms'
import { ERouteConditions, ERoutes, ResultsRoute } from '~/types/routes'
import { ECondition, NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { useFiltersStore } from '~/stores/filtersStore'
import { useStockNotificationStore } from '~/stores/forms/stockNotificationStore'
import { useSettingsStore } from '~/stores/settingsStore'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { ECmsSectionType } from '~/types/cms/contentModelEnum'

type VehicleResults = (NewVehicle | UsedVehicle)[]

definePageMeta({
  name: ERoutes.Results,
  middleware: [
    async (to) => {
      if (import.meta.server) return

      const isRetailerQuery =
        to.query?.Retailer || to.query?.RetailerId || to.query?.RetailerGroup

      if (isRetailerQuery) {
        return
      }

      if (to.path === '/used/results' && !isRetailerQuery) {
        const filtersStore = useFiltersStore()
        filtersStore.resetRetailer()
        return
      }

      const filtersStore = useFiltersStore()
      const customerTypeStore = useCustomerTypeStore()

      if (filtersStore.active.retailers.length) {
        const query: any = {
          ...filtersStore.getActiveQueryParams,
          ...(to.query.finance ? { finance: to.query.finance } : {}),
          ...to.query,
          ...kioskQueryFromCookie()
        }

        return await navigateTo({
          name: ERoutes.Results,
          params: {
            condition: filtersStore.routeCondition,
            type: customerTypeStore.getCustomerTypeRouteParam
          },
          query
        } as ResultsRoute)
      }

      // this should be only used cars with Postcode or nothing in the url
      if (filtersStore.isNew || filtersStore.isRetailerMode) return

      if (filtersStore.retailer) {
        filtersStore.resetRetailer()
      }
    },
    // go back button
    (to) => {
      const filtersStore = useFiltersStore()
      const isSameCondition =
        filtersStore.active.condition ===
        convertCondition.fromConditonRouteParam(
          to.params.condition as ERouteConditions
        )

      if (
        isSameCondition &&
        isEqual(filtersStore.getActiveQueryParams, to.query)
      )
        return

      const { $pinia } = useNuxtApp()
      const vehicleType = convertCondition.fromConditonRouteParam(
        to.params.condition as ERouteConditions
      )

      setFiltersFromRouteQuery(to, vehicleType, $pinia)
    },
    'condition'
  ]
})

const { isEnabled: isCrossSellEnabled } = useFeatureFlag('cross_sell_enabled')

const isCrossSaleTileFound = ref(false)

const shouldShowCrossSell = computed(() => {
  return isCrossSellEnabled.value && isCrossSaleTileFound.value
})

const isKioskMode = useState('kioskmode')

const resultsHeaderRef = ref<HTMLImageElement | null>(null)
const { $api, $dataLayer, $bus } = useNuxtApp()

const filtersStore = useFiltersStore()
const route = useRoute()
const router = useRouter()
const customerTypeStore = useCustomerTypeStore()
const vehiclesSearchStore = useVehiclesSearchStore()
const filterDataStore = useFilterDataStore()
const stockNotificationStore = useStockNotificationStore()
const formsStore = useFormsStore()
const settingsStore = useSettingsStore()
const financeCriteriaStore = useFinanceCriteriaStore()

const isPersonalised = computed(() => financeCriteriaStore.isPersonalised)

const { showSidepanel } = useSidepanel(ESidepanel.FORMS)

useBannerComposition(ERoutes.Results)

const { initOneTag } = useFlashTalking()

const { isNew, sorting, active } = storeToRefs(filtersStore)
const { vehicles, bestMatchVehicles, promoOffer } =
  storeToRefs(vehiclesSearchStore)

interface ISortedBestMatchGroup {
  title: string
  results: any[]
}

interface ISortedBestMatch {
  other?: ISortedBestMatchGroup
  nationwide?: ISortedBestMatchGroup
}

const sortedBestMatch = computed<ISortedBestMatch>(() => {
  const results: ISortedBestMatch = {
    other: {
      title: 'Here are some alternatives available',
      results: []
    },
    nationwide: {
      title: 'Here are some alternatives available nationwide',
      results: []
    }
  }
  // sort bestMatchVehicles into 2 collections, within retailer and outside
  bestMatchVehicles.value.forEach((item: NewVehicle | UsedVehicle) => {
    const key = item.BestMatchDroppedValues?.includes('Showroom')
      ? 'nationwide'
      : 'other'
    results[key] && results[key].results.push(item)
  })

  return {
    ...(results.nationwide?.results.length
      ? { nationwide: results.nationwide }
      : {}),
    ...(results.other?.results.length ? { other: results.other } : {})
  }
})
const specialistModels = computed(() => filterDataStore.getSpecialistModels)

const requiresRetailer = computed(() => {
  return (
    active.value.retailerName.length < 1 &&
    active.value.condition === ECondition.New
  )
})

// Geolocator
const requiresGeolocation = useState(
  'requiresGeolocation',
  () => active.value.condition === ECondition.New
)

const onSuccess: PositionCallback = async (position: GeolocationPosition) => {
  await useFetchRetailerByLocation(
    position.coords.latitude,
    position.coords.longitude,
    isNew.value
  )

  requiresGeolocation.value = false
}

const onError: PositionErrorCallback = () => {
  requiresGeolocation.value = false
}

const preferred = useLocalStorage('preferredRetailerId', null)

if (requiresRetailer.value && !preferred.value && !isKioskMode.value) {
  useGeolocator(onSuccess, onError)
}

const showStockNotification = computed(() => {
  return (
    !isLoading.value && vehicles.value.length === 0 && !requiresRetailer.value
  )
})
const { fetch: fetchVehicles, fetchPromoOffer } = vehiclesSearchStore

const {
  pending,
  status,
  refresh: search
} = useLazyAsyncData('vehicle/search', fetchVehicles, {
  watch: [sorting, active],
  server: false,
  immediate: false
})

useLazyAsyncData('promo-offer', () => fetchPromoOffer(), {
  watch: [active]
})

const isLoading = computed(() => {
  if (status.value === 'idle') return !vehicles.value.length

  return status.value === 'pending'
})

const hasPromoOffer = computed(() => {
  return !isLoading.value && promoOffer.value
})

const analytics = () => {
  // Results page tracking fires from component once results are loadedd
  if (!requiresRetailer.value && isLoading.value) return

  const gaPayload: Partial<IGoogleDataLayerOptions> = {
    vehicle: {
      condition: filtersStore.routeCondition
    },
    filters: [filtersStore.getDataForAnalytics()]
  }

  if (requiresRetailer.value) {
    gaPayload.category = 'preferred showroom'
    gaPayload.pageCategory = 'preferred showroom'
  } else {
    const searchCounts = vehiclesSearchStore.meta.count

    gaPayload.results = {
      ...(filtersStore.routeCondition === ERouteConditions.New
        ? { new_car_results: searchCounts.new }
        : {}),
      ...(filtersStore.routeCondition === ERouteConditions.Used
        ? { used_car_results: searchCounts.used }
        : {})
    }
  }

  $dataLayer.pageView(gaPayload)

  initOneTag()
}

const { data: meta } = useCustomLazyAsyncData('pages/results', async () => {
  const res = await $api.pages.getPage('results')

  const popularModelsSection = findHomeSection(
    res.data?.sections || [],
    ECmsSectionType.POPULAR_MODELS
  )

  if (popularModelsSection) {
    filtersStore.popularModels =
      popularModelsSection.content?.[0].modelIds || []
  }
  return res
})

useCmsSeo(meta)

const { setValuation } = useValuations()

onMounted(() => {
  initMobileLayout()

  $bus.on('data-layer:page-view', analytics)
  $bus.on('filters:shown', closeFinance)

  if (settingsStore.modes.finance) {
    resultsHeaderRef.value?.$el.scrollIntoView()
  }

  if (process.client) {
    setValuation()
  }

  analytics()

  if (!vehicles.value.length) {
    search()
  }
})

onUnmounted(() => {
  $bus.off('data-layer:page-view')
  $bus.off('filters:shown')

  window.onpopstate = null
})

watch(active, (after, before) => {
  if (!isEqual(after, before)) {
    updateUrl()
  }
})

const updateUrl = () =>
  navigateTo({
    name: ERoutes.Results,
    params: {
      condition: filtersStore.routeCondition,
      type: customerTypeStore.getCustomerTypeRouteParam
    },
    query: filtersStore.getActiveQueryParams
  } as ResultsRoute)

if (process.client) {
  watch(
    sorting,
    (val) => {
      if (
        (!val && !route.query.SortId) ||
        val === parseInt(route.query.SortId as string)
      )
        return

      updateUrl()
    },
    { immediate: true }
  )
}

watch(
  pending,
  (after, before) => {
    if (before && status.value === 'success') {
      analytics()
    }
    if (!after && !requiresRetailer && vehicles.value.length === 0) {
      $dataLayer.pageView({
        pageCategory: 'stock notification prompt'
      })
    }
  },
  { immediate: true }
)

const stockNotificationMessage = computed(() => {
  return isKioskMode.value
    ? {
        title: '0 Results',
        text: 'Talk fo your dealer about when your selection will be back in stock'
      }
    : {
        title: {},
        text:
          specialistModels.value.length === 1
            ? 'There are no results found.'
            : 'There are no results found at your preferred Showroom.'
      }
})

const notifyMe = () => {
  if (isNew.value) {
    stockNotificationStore.updatePreselectionWithFilters()
    router.push({
      ...route,
      name: ERoutes.StockNotification
    })
  } else {
    showSidepanel()
    formsStore.updateForm(EFormType.Callback)
  }

  $dataLayer.linkClick({
    category: 'results',
    action: 'callback'
  })
}

const vehiclesCrossSelling = computed<[VehicleResults, VehicleResults]>(() => {
  if (vehicles.value?.length) {
    return [vehicles.value.slice(0, 3), vehicles.value.slice(3)]
  }

  return [[], []]
})

const paginationSearch = () => {
  if (isMobileLayout.value) {
    document.querySelector(mobileScrollSelector)?.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  search()
}

const onUpdateFinance = () => {
  search()
  toggleFinance()
  $bus.emit('qrcode:refresh')
}
// Mobile scrolling
const { isMobileLayout, mobileScrollSelector, initMobileLayout } =
  useMobileScrolling()

const filterNavigationRef = ref<HTMLElement | null>(null)
const resultsContent = ref<HTMLElement | null>(null)

// Finance
const { addOverlayScrollBlocked, removeOverlayScrollBlocked } = useUi()
const financeCalculatorRef = ref<HTMLElement | null>(null)

const closeFinance = () => {
  settingsStore.updateFinanceMode(false)

  if (!isMobileLayout.value) return

  removeOverlayScrollBlocked()
}

const toggleFinance = () => {
  settingsStore.updateFinanceMode(!settingsStore.modes.finance)

  if (!isMobileLayout.value) return

  settingsStore.modes.finance
    ? addOverlayScrollBlocked()
    : removeOverlayScrollBlocked()
}
</script>

<style lang="scss" scoped>
// mobile
@include viewport('md', 'max-width') {
  .results__items {
    :deep(.vehicle-item) {
      display: block;
    }

    :deep(.cross-selling-card) {
      max-height: unset;
    }

    :deep(.os-card) {
      max-height: auto;
    }
  }
}

.results {
  margin: 0 auto;
  width: 100%;
  @include componentHorizontalPadding();

  @include viewport('xl') {
    .notification {
      margin: 0 0 rem(24);
      width: 100%;
    }
  }
}

.results__content {
  margin: 0 auto;
  width: 100%;
  max-width: $containerWidth;

  .body--kiosk & {
    max-width: 1592px;
  }

  .promoOffer {
    margin-bottom: rem(16);
    width: 100%;
  }

  .notification {
    margin: 0 0 rem(8);
  }
}

.results__compare {
  position: sticky;
  bottom: 0;
  margin: 0 auto;
  max-width: 1232px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
  pointer-events: none;
  width: 100%;
  z-index: 20;
}

.results__pagination {
  display: flex;
  justify-content: flex-end;
  margin: rem(32) 0 rem(88);
  width: 100%;
}

.results__bestMatch {
  margin: rem(56) 0;
  padding: rem(16);
  width: 100%;
  height: auto;
  line-height: 1.5;
  color: $black--light;
  font-family: $mb-font-text;
  border: 1px solid $grey--light;
  border-radius: 6px;

  h4 {
    margin-bottom: rem(8);
  }
}

.results__bestMatch__buttons {
  display: flex;
  margin-top: rem(16);
  flex-direction: column;

  @include viewport('xs') {
    flex-direction: row;
  }

  button:not(:first-of-type) {
    margin-left: rem(16);
  }
}
.results__bestMatch__save {
  margin-bottom: rem(16);
  @include viewport('sm') {
    margin-right: rem(16);
    margin-bottom: 0;
  }
}

.results__notification {
  padding: rem(48) rem(24);
  border-top: 1px solid $grey--light;
  border-bottom: 1px solid $grey--light;

  @include viewport('md') {
    padding: rem(88) rem(48);
  }
}
</style>
